import React from 'react'

function Footer() {
    return ( 
        <>
        <div className='copyright'>
            <p>&copy; Copyright Kaur's Website 2023</p>
        </div>
        </>
    )
}

export default Footer